<template>
    <v-card>
        <v-card-subtitle>
            <span class="lstick"></span>
            <h3 class="title font-weight-regular">Historial de cambios de estado</h3>
        </v-card-subtitle>
        <v-card-text>
            <v-data-table :headers="headers" :items="items" :loading="loading" :items-per-page="-1" item-key="id"
                no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
                class="elevation-1" hide-default-footer>
                <template v-slot:item.entity="{ item }">
                    {{ item | resolveEntity }}
                </template>

                <template v-slot:item.name="{ item }">
                    {{ item | resolveName }}
                </template>

                <template v-slot:item.state="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state))">
                        {{ fixStateLabelExtra(item.state) }}
                    </div>
                </template>

                <template v-slot:item.comment="{ item }">
                    {{ item.comment | resolveComment }}
                </template>

                <template v-slot:item.user="{ item }">
                    {{ item | resolveUser }}
                </template>
                <template v-slot:item.date="{ item }">
                    {{ item.created_at | fixDate}} {{ item.created_at | fixDateHour }}
                </template>
                <template v-slot:footer="attrs">
                    <Pagination v-bind="attrs" :reload="reloadData" url="history/dashboard"
                        @loading-data="catchLoading" @load-data="catchDatas"
                        />
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>
import Pagination from "@/components/pagination/Pagination";

export default {
    name: "HistoryChanges",
    components: {
        Pagination
    },
    data(){
        return {
            items: [],
            loading: true,
            headers: [
                {
                    text: "Entidad",
                    align: "start",
                    value: "entity",
                },
                {
                    text: "Nombre",
                    align: "start",
                    value: "name",
                },
                {
                    text: "Estado",
                    align: "start",
                    value: "state",
                },
                {
                    text: "Comentario",
                    align: "start",
                    value: "comment",
                },
                {
                    text: "Usuario",
                    align: "start",
                    value: "user",
                },
                {
                    text: "Rol",
                    align: "start",
                    value: "type_role",
                },
                {
                    text: "Fecha",
                    align: "start",
                    value: "date",
                },
            ],
            reloadData: false,
        }
    },
    filters: {
        resolveComment(value) {
            return value?value:' - ';
        },
        resolveEntity(value){
            if(value.clinic){
                return 'Clínica';
            }
            if (value.doctor){
                return 'Médico';
            }
            if (value.hospital){
                return 'Hospital'
            }
            if (value.hotel){
                return 'Hotel';
            }
            if (value.house){
                return 'Casa';
            }
            if (value.laboratory){
                return 'Laboratorio';
            }
            if (value.leisure){
                return 'Lugar';
            }
            if (value.pharmaceutic){
                return 'Farmacia';
            }
            if (value.restaurant){
                return 'Restaurante';
            }
            if (value.transportation){
                return 'Transporte';
            }
            if (value.vineyard){
                return 'Viñedo';
            }
            if (value.assiting_living) {
                return 'Assisting Living';
            }
            return 'N/A';
        },
        resolveName(value){
            if (value.clinic){
                return value.clinic.name;
            }
            if (value.doctor){
                return value.doctor.name;
            }
            if (value.hospital){
                return value.hospital.name
            }
            if(value.hotel){
                return value.hotel.name;
            }
            if(value.house){
                return value.house.name;
            }
            if (value.laboratory){
                return value.laboratory.name;
            }
            if (value.leisure){
                return value.leisure.name;
            }
            if (value.pharmaceutic){
                return value.pharmaceutic.name;
            }
            if (value.restaurant){
                return value.restaurant.name;
            }
            if (value.transportation){
                return value.transportation.name;
            }
            if (value.vineyard){
                return value.vineyard.name;
            }
            if (value.assiting_living) {
                return value.assiting_living.name;
            }
            return 'N/A';
        },
        resolveUser(value){
            return value.user ? `${value.user.name} ${value.user.last_name}`:'';
        },
        fixDate(dateStr){
            const date = new Date(dateStr);
            let month = date.getMonth() + 1;
            month = month>9?month: `0${month}`;
            return `${date.getFullYear()}-${month}-${date.getDate()}`;
        },
        fixDateHour(dateStr){
            const date = new Date(dateStr);
            let hours = date.getHours();
            hours = hours < 10 ? `0${hours}` : hours;
            let minutes = date.getMinutes();
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            let seconds = date.getSeconds();
            seconds = seconds < 10 ? `0${seconds}` : seconds;
            return `${hours}:${minutes}:${seconds}`;
        }
    },
    methods: {
        reload() {
            this.reloadData = !this.reloadData;
        },
        catchDatas(value) {
            this.items = value;
        },
        catchLoading(value) {
            this.loading = value
        },
        fixStateLabelExtra(state) {
            switch (state !== "") {
                case state !== "":
                if (state === 0 || state === "Pendiente") {
                    return "Pendiente";
                } else if (state === 1 || state === "Aprobado") {
                    return "Aprobado";
                } else {

                    return "Rechazado";
                }
            }
        },
        fixStateColor(state) {
            switch (state) {
                case 'Rechazado':
                return "background-color: #CA2B2BFF;";
                case 'Pendiente':
                return "background-color: #6E87DBFF;";
                case 'Aprobado':
                return "background-color: #6bc263;";
                default:
                return "background-color: #000;";
            }
        },
    },
}
</script>
<style>
.custom-state-div {
  padding: 10px;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
  text-align: center;
  width: max-content;
  justify-self: center;
}
</style>