import { render, staticRenderFns } from "./StadisticEntities.vue?vue&type=template&id=18ebe7e8&scoped=true"
import script from "./StadisticEntities.vue?vue&type=script&lang=js"
export * from "./StadisticEntities.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ebe7e8",
  null
  
)

export default component.exports